<template>
  <v-menu
    close-on-content-click
    transition="scale-transition"
    offset-y
    nudge-top="25"
    max-width="290px"
    min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-on="disabled ? null : on"
        :attrs="attrs"
        :value="dateFormatted"
        :label="label || 'Date'"
        background-color="input"
        :required="required"
        :rules="rules || []"
        prepend-inner-icon="mdi-calendar"
        validate-on-blur
        readonly
        :disabled="disabled === true"
        outlined
        dense>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="minDate || undefined"
      :max="maxDate || nowDate"
      :show-current="currentDate || true"
      no-title>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { dateFormat } from '@/mixins/date-format'
export default {
  data () {
    return {
      date: null,
      nowDate: new Date().toISOString().slice(0, 10)
    }
  },
  name: 'DateTextField',
  mixins: [dateFormat],
  props: ['value', 'label', 'required', 'rules', 'minDate', 'maxDate', 'disabled', 'currentDate'],
  watch: {
    date: {
      handler (newValue) {
        if (newValue) {
          this.$emit('input', newValue)
        }
      }
    },
    value: {
      handler (newValue) {
        if (newValue) {
          this.date = newValue
        }
      }
    }
  },
  created () {
    if (this.value) {
      this.date = this.value
    }
  },
  computed: {
    dateFormatted () {
      return this.value ? this.formatDate(this.value) : ''
    }
  }
}
</script>
