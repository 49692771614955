<!-- Sub-modal to rebill or reverse a batch from the BatchDetails.vue modal -->
<template>
  <v-dialog :value="value" persistent width="700px">
  <v-card flat>
    <v-card-title>
      Rebill/Reverse Batch
    </v-card-title>
    <v-card-text class="pb-0">
      <v-form ref="form" lazy-validation>
        <v-container class="mx-0 px-0 mt-2">
          <v-row>
            <v-col>
              <v-text-field
                v-model="this.selectedBatch.name"
                item-text="name"
                readonly
                label="Reverse/Rebill Target"
                hide-details="auto"
                outlined
                dense>
              </v-text-field>
            </v-col>
            <v-col cols='auto'>
              <v-checkbox dense
                v-model="batch.batch_type"
                label="Rebill"
                value="REBILL"
                >
                </v-checkbox>
            </v-col>
            <v-col cols='auto'>
                <v-checkbox dense
                v-model="batch.batch_type"
                label="Reversal"
                value="REVERSAL"
                >
                </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="batch.name"
                label="Name*"
                required
                :rules="[v => !!v || 'Name is required']"
                hide-details="auto"
                outlined
                dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="batch.promotion_id"
                label="Promotion*"
                :items="promoItems"
                multiple
                item-text="promo_name"
                item-value="promo_id"
                hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true}"
                solo
                outlined
                small-chips
                deletable-chips
                dense>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                multiple
                v-model="parties"
                label="Stores*"
                required
                :items="stores"
                item-text="store_name_number"
                item-value="party_id"
                :rules="[v => !!v || 'Party ID is required']"
                hide-details="auto"
                solo
                small-chips
                deletable-chips
                outlined
                dense>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-row dense>
      <v-col cols="auto">
        <small>*Required field</small>
      </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row dense>
        <v-col cols="auto" class="ml-auto">
          <v-btn text
            color="grey darken-2"
            @click="$emit('update:value', false)">
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="success"
            @click="createBatch" 
            :loading="loading"
            :disabled="loading">
            Start
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>
<script>
import BillingBatch from '@/axios/billing-batch'
import DateTextField from '@/components/DateTextField.vue'
import { displayAlert } from '@/mixins/displayAlert'
import { validParties } from '@/mixins/valid-parties'
export default {
  data () {
    return {
      loading: false,
      validParties: [],
      batchTypes: ['SCAN', 'REVERSAL', 'REBILL'],
      batches:[],
      batch: {
        responsible_party_id: null,
        start_date: null,
        end_date: null,
        env: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
        user_id: null,
        name: null,
        batch_time: new Date().toJSON().slice(0, -1),
        status: 'PENDING',
        last_update_date: new Date().toJSON().slice(0, -1),
        batch_type: 'REBILL',
        promotion_id: null,
        reversal_batch_id: null,
        party_id: {"parties":[]}
      },
      targetBatch:{},
      parties:[],
      promoItems:[],
      stores:[]
    }
  },
  name: 'RebillReverse',
  props: ['value', 'selectedBatch'],
  mixins: [displayAlert, validParties],
  components: { DateTextField },
  async created () {
    this.validParties = await this.getValidParties(this.responsibleParties)
    await this.getPromoItems()
    await this.getStores()
  },
  computed: {
    responsibleParties () {
      return this.$store.getters.responsibleParties
    }
  },
  methods: {
    async createBatch () {
      this.loading = true
      const isValid = this.validate()
      if (!isValid) {
        this.loading = false
        return
      }
      if (this.batch.batch_type !== 'REVERSAL'){
        this.batch.reversal_batch_id = null
      }
      this.loadInfo()
      this.loadParties()
      try {
        this.batch.user_id = this.$auth.user.email
        const res = await BillingBatch.post(this.batch)
        if (res?.status === 201) {
          this.emitAlert(true, 'success', 'Successfully started new batch billing run')
          this.$emit('close', true)
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
        this.$emit('update:value', false)
      }
    },
    async getPromoItems(){
       this.loading = true
       await this.$BillingBatch.getBatchPromo(this.selectedBatch.id).then(res => {
           this.promoItems = res.data
       }).catch(err => {
         this.handleError(err)
       }).finally( () =>{
         this.loading = false})
   },
   async getStores(){
       this.loading = true
       await this.$BillingBatch.getBatchStores(this.selectedBatch.id).then(res => {
           this.stores = res.data
       }).catch(err => {
         this.handleError(err)
       }).finally( () =>{
         this.loading = false})
   },
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },
    loadInfo(){
      if (this.batch.batch_type === 'REVERSAL'){
        this.batch.reversal_batch_id = this.selectedBatch.id
      }
      this.batch.responsible_party_id = this.selectedBatch.responsible_party_id
      this.batch.start_date = this.selectedBatch.start_date
      this.batch.end_date = this.selectedBatch.end_date
    },
    loadParties(){
      this.parties.forEach(element => {
        this.batch.party_id.parties.push({"party_id":element})
      });
    }
  }
}
</script>
